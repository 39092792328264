@import url(https://fonts.googleapis.com/css2?family=Assistant&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: "Inter", "Poppins", "Arial", "Assistant", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar:hover {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*{box-sizing:border-box}a{text-decoration:none;color:rgba(0,0,0,.54)}.card{border-radius:10px;background-color:#fff;box-shadow:rgba(49,53,59,.15) 0px 1px 6px 0px}.p{padding:1rem}
